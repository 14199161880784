import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SessionService } from '@parashift/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ColumnsService {
  private toggleLeftColumnSubject = new Subject<boolean>();
  private showLeftSubject = new Subject<string>();

  toggleLeftColumn$ = this.toggleLeftColumnSubject.asObservable();
  showLeft$ = this.showLeftSubject.asObservable();

  identifiersLeft = [];
  iconsLeft = [];

  private toggleRightColumnSubject = new Subject<boolean>();
  private showRightSubject = new Subject<string>();

  toggleRightColumn$ = this.toggleRightColumnSubject.asObservable();
  showRight$ = this.showRightSubject.asObservable();

  identifiersRight = [];
  iconsRight = [];

  showLeftToggle = false;
  showRightToggle = false;

  toggleLeftModel = null;
  toggleRightModel = null;

  constructor(private sessionService: SessionService) {}

  toggleLeftColumn(toggle: boolean, identifier: string, store = true) {
    this.toggleLeftColumnSubject.next(toggle);
    this.showLeftSubject.next(identifier);
    if (store) {
      this.storeState(toggle, identifier, 'left');
    }
  }

  toggleRightColumn(toggle: boolean, identifier: string, store = true) {
    this.toggleRightColumnSubject.next(toggle);
    this.showRightSubject.next(identifier);
    if (store) {
      this.storeState(toggle, identifier, 'right');
    }
  }

  restoreLeftColumnState() {
    const layout = this.sessionService.layout || {};

    if (layout.column_left) {
      for (const key in layout.column_left) {
        if (Object.prototype.hasOwnProperty.call(layout.column_left, key) && layout.column_left[key]) {
          this.toggleLeftColumn(layout.column_left[key], key);
          this.toggleLeftModel = key;
        }
      }
    }
  }

  restoreRightColumnState() {
    const layout = this.sessionService.layout || {};

    if (layout.column_right) {
      for (const key in layout.column_right) {
        if (Object.prototype.hasOwnProperty.call(layout.column_right, key) && layout.column_right[key]) {
          this.toggleRightColumn(layout.column_right[key], key);
          this.toggleRightModel = key;
        }
      }
    }
  }

  private storeState(toggle: boolean, identifier: string, column: string) {
    if (identifier) {
      const layout = this.sessionService.layout || {};
      if (column === 'left') {
        layout.column_left = { [identifier]: toggle };
      } else {
        layout.column_right = { [identifier]: toggle };
      }
      this.sessionService.layout = layout;
    }
  }
}
