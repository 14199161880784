import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightMatch',
  standalone: true
})
export class HighlightMatchPipe implements PipeTransform {
  transform(value: string, term: string): string {
    return value && term ? value.replace(new RegExp(term, 'ig'), (match) => `<strong>${match}</strong>`) : value;
  }
}
